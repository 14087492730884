/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import { Box, Button, Tab, TextField, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import NoticeBoard from "../NoticeBoard";
import IntegratedActivityTable from "../IntegratedActivityTable";
import * as UserAction from "../../../Action/AdminDashboard";
import "./style.scss";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ListingView from "../BookingOverView/ListingView";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { DailyOverview, ReservationInfo } from "./dashboardDetails";



const Home = (props) => {
  const [date, setDate] = useState(new Date());

  const [value, setValue] = useState("Seating and Reservation Overview");

  const [startDate, setStartDate] = React.useState(dayjs());
  const [endDate, setEndDate] = React.useState(dayjs().add(7, "day"));

  const [type, setType] = useState("All");

  const hotelReducer = useSelector((state) => ({
    selectedOutlet: state.hotelReducer.selectedOutlet,
    showFloorPlan: state.hotelReducer.showFloorPlan,
    dashboardData: state.hotelReducer.dashboardData,
  }));

  useEffect(() => {
    props.actions.userAction.dashboardData(
      hotelReducer.selectedOutlet?.outlet.id,
      startDate,
      endDate,
      type
    );
  }, [
    hotelReducer?.selectedOutlet && hotelReducer.selectedOutlet?.outlet.id,
    startDate,
    endDate,
    type,
  ]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTypeChange = (event) => {
    // Get the text content of the clicked button
    const newType = event.target.textContent;
    setType(newType);
  };

  return (
    <div className="home">
      <div className="home-inner">
        <div className="home-header">
          <span className="header-text">Dashboard</span>
        </div>
        <div className="home-header">
          <div className="home-header-inner">
            <span className="header-inner-text">
              {hotelReducer.selectedOutlet?.outlet.name}
            </span>
          </div>
        </div>
      </div>
      <NoticeBoard date={date} />

      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab
                label="Seating and Reservation Overview"
                value="Seating and Reservation Overview"
              />
              <Tab
                label="Integrated Activity List"
                value="Integrated Activity List"
              />
            </TabList>
          </Box>
          <TabPanel
            value="Seating and Reservation Overview"
            sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <div className="tabs-header">
              <div className="toggle-buttons">
                <Button
                  variant={type === "All" ? "contained" : "outlined"}
                  onClick={handleTypeChange}
                >
                  All
                </Button>
                <Button
                  variant={type === "Lunch" ? "contained" : "outlined"}
                  onClick={handleTypeChange}
                >
                  Lunch
                </Button>
                <Button
                  variant={type === "Dinner" ? "contained" : "outlined"}
                  onClick={handleTypeChange}
                >
                  Dinner
                </Button>
              </div>

              <Box className="dashbord-date">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 2 }}
                      >
                        <DatePicker
                          inputFormat="DD-MM-YYYY"
                          label="Start Date"
                          value={startDate}
                          onChange={(newValue) => {
                            setStartDate(newValue);
                            if (newValue.isAfter(endDate)) {
                              setEndDate(newValue); // Adjust end date if start date is after end date
                            }
                          }}
                          renderInput={(params) => (
                            <TextField size="small" fullWidth {...params} />
                          )}
                        />
                        <span>To</span>
                        <DatePicker
                          inputFormat="DD-MM-YYYY"
                          label="End Date"
                          value={endDate}
                          minDate={startDate} // Prevent selecting an end date before the start date
                          onChange={(newValue) => setEndDate(newValue)}
                          renderInput={(params) => (
                            <TextField size="small" fullWidth {...params} />
                          )}
                        />
                      </Box>
                    </Box>
                  </div>
                </LocalizationProvider>
              </Box>
            </div>

            {hotelReducer && hotelReducer.dashboardData && (
              <ReservationInfo
                reservationCount={
                  hotelReducer.dashboardData.reservationData.reservationCount
                }
                pax={hotelReducer.dashboardData.reservationData.pax}
                creditHold={
                  hotelReducer.dashboardData.reservationData.creditHold
                }
              />
            )}
            {hotelReducer && hotelReducer.dashboardData && (
              <div className="daily-overview">
                <Typography fontWeight={400} variant="h5" component="div">
                  Daily Overview
                </Typography>

                <DailyOverview
                  seatingData={hotelReducer.dashboardData.seatingData}
                  events={hotelReducer.dashboardData.events}
                  rooms={hotelReducer.dashboardData.rooms}
                  preOrders={hotelReducer.dashboardData.preOrders}
                />
              </div>
            )}

            <Typography fontWeight={400} variant="h5" component="div">
              Reservation List Overview
            </Typography>

            <ListingView isOnDashbord={false} />
          </TabPanel>
          <TabPanel value="Integrated Activity List">
            <IntegratedActivityTable />
          </TabPanel>
        </TabContext>
      </Box>

      {/* <div className="user-groups" style={{ height: "calc(100vh - 275px)" }}>
        <h1 className="groups-header-2nd">Floor Plan</h1>
        {hotelReducer.showFloorPlan && (
          <SeatingView
            date={date}
            drawerOpen={open}
            hasReadPermission={true}
            hasUpdatePermission={false}
            hasCreatePermission={false}
          />
        )}
      </div> */}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(Home);
