import { Card, CardContent, Typography } from "@mui/material";
import "./style.scss";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import moment from "moment-timezone";



export const ReservationInfo = ({ reservationCount, pax, creditHold }) => {
    return (
      <div className="reservation-info">
        <Card className="card">
          <CardContent>
            <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
              Reservation
            </Typography>
            <Typography fontWeight={600} variant="h4" component="div">
              {reservationCount || 0}
            </Typography>
          </CardContent>
        </Card>
  
        <Card className="card">
          <CardContent>
            <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
              Pax
            </Typography>
  
            <Typography sx={{ fontSize: 16 }} fontWeight={600}>
              Adult: {pax?.adult || 0}
            </Typography>
  
            <Typography sx={{ fontSize: 16 }} fontWeight={600}>
              Child: {pax?.child || 0}
            </Typography>
          </CardContent>
        </Card>
  
        <Card className="card">
          <CardContent>
            <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
              Credit Hold
            </Typography>
            <Typography fontWeight={600} variant="h4" component="div">
              ${creditHold || 0}
            </Typography>
          </CardContent>
        </Card>
      </div>
    );
  };

const SeatingOverview = ({ seatingData }) => (
  <Card className="card">
    <Typography fontSize={18} fontWeight={600} variant="h6">
      Seating Overview
    </Typography>
    <div className="scroolable">
      {seatingData &&
        seatingData.map((area, index) => (
          <div className="seating-overview-progress" key={index}>
            <Typography fontSize={14} fontWeight={700}>
              {`${area.name} (${area.capacity}pax)`}
            </Typography>
            <Typography fontSize={14} fontWeight={400}>
              {`${area.confirmed} Confirmed | ${area.booked} Booked`}
            </Typography>
            <div className="progress-text">
              <Typography marginTop={1} fontSize={14} fontWeight={400}>
                Currently Occupied Seats
              </Typography>
              <Typography marginTop={1} fontSize={14} fontWeight={400}>
                {`${area.occupied}/${area.capacity}`}
              </Typography>
            </div>
            <BorderLinearProgress
              variant="determinate"
              value={(area.occupied / area.capacity) * 100}
            />
          </div>
        ))}
    </div>
  </Card>
);

const EventsOverview = ({ events }) => (
  <Card className="card">
    <Typography fontSize={18} fontWeight={600} variant="h6">
      Events Overview
    </Typography>
    <div className="scroolable">
      {events &&
        events.map((event, index) => (
          <Card className="event-inner-card" key={index}>
            <Typography fontSize={14} fontWeight={700}>
              {event.Customer.name}
            </Typography>
            <Typography fontSize={14}>
              Phone: {event.Customer.mobileNo}
            </Typography>
            <Typography fontSize={14}>
              Date: {moment(event.bookingDate).format("DD-MM-YYYY")}
            </Typography>
            <Typography fontSize={14}>
              Time:{" "}
              {moment(event.bookingStartTime, "HH:mm:ss").format("hh:mm A")}
            </Typography>
            <Typography fontSize={14}>
              Adult: {event.adult || 0} &nbsp;&nbsp; Child: {event.child || 0}
            </Typography>
            <Typography fontSize={14}>
              Special Instruction: {event.specialRequest || "N/A"}
            </Typography>
          </Card>
        ))}
    </div>
  </Card>
);

const PrivateRooms = ({ rooms }) => (
  <Card className="card">
    <Typography fontSize={18} fontWeight={600} variant="h6">
      Private Room
    </Typography>
    <div
      className="scroolable event-inner-card"
      style={{ gap: "20px", border: "unset", boxShadow: "unset" }}
    >
      {rooms &&
        rooms.map((room, index) => {
          // Get first table name from OutletTableBooking array
          const tableName =
            room.OutletTableBooking?.[0]?.OutletTable?.name || "";

          return (
            <div className="private-table" key={index}>
              <div className="table-name">{tableName}</div>
              <div className="private-info">
                <div className="private-info-inner">
                  <Typography fontSize={14} fontWeight={700}>
                    {room.Customer.name}
                  </Typography>
                  <Typography fontSize={14} fontWeight={700}>
                    {moment(room.bookingDate).format("DD-MM-YYYY")} &nbsp;&nbsp;{" "}
                    {moment(room.bookingStartTime, "HH:mm:ss").format(
                      "hh:mm A"
                    )}
                  </Typography>
                </div>
                <div className="private-info-inner">
                  <Typography fontSize={14}>{room.bookingType}</Typography>
                  <Typography fontSize={14}>
                    Adult: {room.noOfAdult} &nbsp;&nbsp; Child: {room.noOfChild}
                  </Typography>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  </Card>
);

const PreOrderItems = ({ preOrders }) => (
  <Card className="card">
    <Typography fontSize={18} fontWeight={600} variant="h6">
      Pre Order Items
    </Typography>
    <div className="scroolable">
      {preOrders &&
        preOrders.map((order, index) => (
          <Card
            className="event-inner-card"
            key={index}
            style={{
              gap: "20px",
              padding: "0",
              border: "unset",
              boxShadow: "unset",
            }}
          >
            <div className="private-table" style={{ backgroundColor: "unset" }}>
              <div className="private-info">
                <div
                  className="private-info-inner"
                  style={{ marginBottom: "10px" }}
                >
                  <Typography fontSize={14} fontWeight={700}>
                    {order.Customer.name}
                  </Typography>
                  <Typography fontSize={14} fontWeight={700}>
                    {moment(order.bookingDate).format("DD-MM-YYYY")}{" "}
                    &nbsp;&nbsp;{" "}
                    {moment(order.bookingStartTime, "HH:mm:ss").format(
                      "hh:mm A"
                    )}
                  </Typography>
                </div>
                {order.basket &&
                  order.basket.length > 0 &&
                  order.basket.map((item, itemIndex) => (
                    <div className="private-info-inner" key={itemIndex}>
                      <Typography fontSize={14}>{item.name}</Typography>
                      <Typography fontSize={14}>x{item.qty}</Typography>
                    </div>
                  ))}
              </div>
            </div>
          </Card>
        ))}
    </div>
  </Card>
);

export const DailyOverview = ({ seatingData, events, rooms, preOrders }) => (
  <div className="daily-overview-card">
    <SeatingOverview seatingData={seatingData} />
    <EventsOverview events={events} />
    <PrivateRooms rooms={rooms} />
    <PreOrderItems preOrders={preOrders} />
  </div>
);

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));
  