export const templateType = [
  { id: "BOOKED", value: "Booked" },
  { id: "CONFIRMED", value: "Confirmed" },
  { id: "BOOKED_PAYMENT", value: "Booked Payment" },
  { id: "ADMIN_RESERVATION_PAYMENT_LINK", value: "Admin Reservation Payment" },
];

export const smsTemplateType = [
  { id: "BOOKED", value: "Booked" },
  { id: "CONFIRMED", value: "Confirmed" },
  { id: "RESERVATION", value: "Reservation" },
];
