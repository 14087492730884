import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Alert, Typography } from "@mui/material";
import CheckoutForm from "../CheckoutForm";
import { adminReservationPaymentToken } from "../../../Action/Customer";
import { useDispatch } from "react-redux";
import { INPROGRESS, STOPLOADER } from "../../../utils/Customer/Constant";

const AdminPaymentReservation = () => {
  const { orderId } = useParams();
  const dispatch = useDispatch();

  const [stripePayment, setStripePayment] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch the Stripe client secret using the orderId from params
  useEffect(() => {
    if (!orderId) {
      setError("Order ID is missing from the URL.");
      return;
    }

    const fetchPaymentDetails = async () => {
      setLoading(true);
      dispatch({ type: INPROGRESS });
      setError(null);
      try {
        const { data } = await adminReservationPaymentToken(orderId);

        if (!data.data.client_secret) {
          throw new Error("Incomplete payment details received.");
        }
        setStripePayment(data.data.client_secret);
      } catch (err) {
        if (err && err.response) {
          setError(err.response.data.message);
        } else {
          setError(err.message.toString());
        }
      } finally {
        setLoading(false);
        dispatch({ type: STOPLOADER });
      }
    };

    fetchPaymentDetails();
  }, [orderId]);

  const handleClosePaymentPopup = () => {
    setStripePayment(null);
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4, p: 2 }}>
      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {`Error: ${error}. Please try again later or contact support.`}
        </Alert>
      )}

      {!loading && !error && stripePayment && (
        <>
          <CheckoutForm
            open={true}
            stripePayment={stripePayment}
            handleClosePaymentPopup={handleClosePaymentPopup}
          />
        </>
      )}

      {loading && (
        <Typography variant="body1" align="center" sx={{ mt: 2 }}>
          Loading payment details, please wait...
        </Typography>
      )}
    </Container>
  );
};

export default AdminPaymentReservation;
