/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, Fragment } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Stack,
  TextField,
  Card,
} from "@mui/material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import PhoneInput from "react-phone-input-2";
import { isEmpty, cloneDeep } from "lodash";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import parsePhoneNumber from "libphonenumber-js";

import * as UserAction from "../../../../../Action/AdminDashboard";
import {
  INPROGRESS,
  ERROR,
  LOGOUT,
  STOPLOADER,
  GET_BOOKING_RESERVATION,
} from "../../../../../utils/AdminDashboard/Constant";
import { clearAccessToken } from "../../../../../utils";
import ConfirmTable from "../ConfirmTable";
import ENVIRONMENT_VARIABLES from "../../../../../environment.config";
import { toast } from "react-toastify";

let moment = require("moment-timezone");

const NormalBooking = (props) => {
  const dummyDining = {
    id: -1,
    originalPrice: 0,
    price: 0,
    name: "Reservation",
    qty: 0,
    privateRoom: "",
    image: "images/defaultDining.jpeg",
    dailyTotalQtyleft: 2,
    isEnable: true,
  };
  const dispatch = useDispatch();
  const { open, handleCloseBookingTable, handleChangeBookingType } = props;

  const reservationType = [
    { id: "NORMAL_RESERVATION", value: "New Booking" },
    { id: "PRIVATE_EVENT", value: "New Event" },
  ];

  const [timeSlots, setTimeSlots] = useState(null);
  const [confirmTable, setConfirmTable] = useState(false);
  const [timeOut, setTimeOut] = useState(null);
  const [dinningData, setDinningData] = useState([]);
  const [filterDining, setFilterDining] = useState(dummyDining);
  const [menuItem, setMenuItem] = useState([]);
  const [filterPrivate, setFilterPrivate] = useState(null);
  const [privateData, setPrivateData] = useState([]);
  const [review, setReview] = useState(false);
  const [selectedTables, setSelectedTables] = useState([]);
  const [bookingTable, setBookingTable] = useState({
    outletId: props.selectedOutlet.outlet.id,
    date: props.filterData.date,
    name: "",
    lastName: "",
    email: null,
    noOfAdult: null,
    noOfChild: null,
    mobileNo: "",
    dinningOptions: [],
    menuItem: [],
    privateRoom: {},
    bookingType: reservationType[0].id,
    specialRequest: "",
    time: "",
  });

  const selectedOutlet = useSelector(
    (state) => state.hotelReducer.selectedOutlet
  );

  useEffect(() => {
    if (selectedOutlet) {
      setBookingTable({
        ...bookingTable,
        outletId: selectedOutlet.outlet.id,
      });
    }
  }, [selectedOutlet]);

  const handleChangeBookingTable = (event) => {
    clearTimeout(timeOut);
    const field = event.target.name;
    const value = event.target.value;

    const updatedBookingTable = { ...bookingTable, [field]: value };

    switch (field) {
      case "noOfAdult":
      case "noOfChild":
        setTimeOut(
          setTimeout(() => {
            if (
              updatedBookingTable.noOfAdult > 0 &&
              updatedBookingTable.noOfChild >= 0
            ) {
              getTimeSlotByOutletId(updatedBookingTable);
            }
          }, 1000)
        );
        break;

      case "startTime":
        setTimeOut(
          setTimeout(() => {
            if (value) getDiningOption(updatedBookingTable);
          }, 1000)
        );
        break;

      case "dinningOptions":
        // Store it as an array of one or more items
        const selectedDining = dinningData.find(
          (option) => option.id === value
        );

        updatedBookingTable.dinningOptions = selectedDining
          ? [selectedDining]
          : [];
        break;

      case "menuItem": {
        const selectedIds = event.target.value;
        // Filter the full menuItem list to match only selected items
        const selectedMenuItems = menuItem.filter((m) =>
          selectedIds.includes(m.id)
        );
        updatedBookingTable.menuItem = selectedMenuItems;
        break;
      }

      case "privateRoom":
        const selectedRoom = privateData.find((room) => room.id === value);
        updatedBookingTable.privateRoom = selectedRoom || {};
        break;

      default:
        break;
    }

    setBookingTable(updatedBookingTable);
  };

  const handleCloseConfirmTable = () => {
    setConfirmTable(false);
  };

  const handleOpenConfirmTable = () => {
    setConfirmTable(true);
  };

  const getTimeSlotByOutletId = (bookingTable) => {
    if (bookingTable.bookingType === "NORMAL_RESERVATION") {
      setTimeSlots(null);
      setBookingTable({
        ...bookingTable,
        startTime: null,
        dinningOptions: null,
        menuItem: [],
        privateRoom: null,
      });
      const data = {
        ...bookingTable,
        date: moment(bookingTable.date).format("DD-MM-YYYY"),
        checkPax: "true",
      };
      dispatch({ type: INPROGRESS });
      UserAction.getTimeSlot(data)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.data) {
              const timeSlots = response.data.data[0]?.timeSlots;
              if (timeSlots && timeSlots.length > 0) {
                setTimeSlots(timeSlots);
                dispatch({
                  type: STOPLOADER,
                });
              } else {
                dispatch({
                  type: ERROR,
                  data: {
                    error_msg: "Service unavailable",
                  },
                });
              }
            }
          }
        })
        .catch((error) => {
          if (error && error.response) {
            if (error.response.status === 401) {
              clearAccessToken();
              dispatch({
                type: LOGOUT,
              });
            } else {
              dispatch({
                type: ERROR,
                data: { error_msg: error.response.data.message },
              });
            }
          } else {
            dispatch({
              type: ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    } else {
      setTimeSlots(null);
      setBookingTable({
        ...bookingTable,
        startTime: null,
        dinningOptions: null,
        menuItem: [],
        privateRoom: null,
      });
      const data = {
        ...bookingTable,
        date: moment(bookingTable.startDate).format("DD-MM-YYYY"),
      };
      dispatch({ type: INPROGRESS });
      UserAction.getTimeSlot(data)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.data) {
              const timeSlots = response.data.data[0]?.timeSlots;
              if (timeSlots && timeSlots.length > 0) {
                setTimeSlots(timeSlots);
                dispatch({
                  type: STOPLOADER,
                });
              } else {
                dispatch({
                  type: ERROR,
                  data: {
                    error_msg: "Service unavailable",
                  },
                });
              }
            }
          }
        })
        .catch((error) => {
          if (error && error.response) {
            if (error.response.status === 401) {
              clearAccessToken();
              dispatch({
                type: LOGOUT,
              });
            } else {
              dispatch({
                type: ERROR,
                data: { error_msg: error.response.data.message },
              });
            }
          } else {
            dispatch({
              type: ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    }
  };

  const getBookingTablesAction = () => {
    handleOpenConfirmTable();
  };

  const confirmTables = () => {
    booking(selectedTables);
  };

  const booking = (tablesList) => {
    const tableIds = tablesList.map((tableData) => tableData.id);
    const data = {
      ...bookingTable,
      mobileNo: `+${bookingTable.mobileNo}`,
      outletTables: tableIds.join(","),
    };

    dispatch({ type: INPROGRESS });
    UserAction.getBookingReservation(data, data.outletId)
      .then((response) => {
        const { status, data: responseData } = response;
        console.log(responseData, "responseData");
        if (status === 201 || status === 200) {
          dispatch({ type: GET_BOOKING_RESERVATION, data: responseData });
          dispatch({ type: STOPLOADER });
          handleCloseBookingTable();
          handleCloseConfirmTable();
          setBookingTable({});
          toast.success("Booking created successfully & Email sent!!");
        }
      })
      .catch((error) => {
        handleCloseConfirmTable();
        if (error?.response) {
          if (error.response.status === 401) {
            clearAccessToken();
            dispatch({ type: LOGOUT });
          } else {
            dispatch({
              type: ERROR,
              data: { error_msg: error.response.data.message },
            });
          }
        } else {
          dispatch({
            type: ERROR,
            data: { error_msg: error.message.toString() },
          });
        }
      });
  };

  const getDiningOption = (bookingTable) => {
    const data = {
      date: moment(bookingTable.date, "YYYY-MM-DD").format("DD-MM-YYYY"),
      exactTime: bookingTable.startTime,
      noOfAdult: Number(bookingTable.noOfAdult),
      noOfChild: Number(bookingTable.noOfChild),
    };
    dispatch({ type: INPROGRESS });
    UserAction.getDiningOptionByOutletId(data, bookingTable.outletId)
      .then((response) => {
        if (response.status === 200) {
          setDinningData([dummyDining, ...response.data.data.diningOptions]);

          if (bookingTable.diningOptions) {
            setFilterDining(bookingTable.diningOptions);
          }

          if (response.data.data.menu) {
            let preOrderItemList = [];

            response.data.data.menu.map((data) => {
              if (checkMealTiming(data, bookingTable))
                data.PreOrderItemDbModel.map((product) =>
                  preOrderItemList.push({ ...product, qty: 0 })
                );
            });

            setMenuItem(preOrderItemList);
          }

          if (response.data.data.privateRoom) {
            setPrivateData(response.data.data.privateRoom);
          }

          if (bookingTable.privateRoom) {
            setFilterPrivate(bookingTable.privateRoom);
          }

          dispatch({
            type: STOPLOADER,
          });
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch({
            type: ERROR,
            data: { error_msg: error.response.data.message },
          });
        } else {
          dispatch({
            type: ERROR,
            data: { error_msg: error.message.toString() },
          });
        }
      });
  };

  const getPrivateRoom = () => {
    if (filterDining.overridePrivateRoom) {
      return [];
    }
    return privateData;
  };

  const checkMealTiming = (mealType, bookingTable) => {
    let isValidMealType = false;

    const formatedDate = moment(bookingTable.date).format("DD-MM-YYYY");

    const requestedDateAndTime = getFormatedDateAndTime(
      formatedDate,
      bookingTable.startTime
    );
    mealType.tradingHours.map((mealTiming) => {
      const startMealTiming = getFormatedDateAndTime(
        formatedDate,
        mealTiming.openingTime
      );
      const endMealTiming = getFormatedDateAndTime(
        formatedDate,
        mealTiming.closingTime
      );

      if (
        requestedDateAndTime.isBetween(
          startMealTiming,
          endMealTiming,
          undefined,
          "[]"
        )
      ) {
        isValidMealType = true;
      }
    });
    return isValidMealType;
  };

  const getFormatedDateAndTime = (date, time) => {
    const splitHours = time.split(":");
    const formatedDate = moment(date, "DD-MM-YYYY").set({
      hour: splitHours[0],
      minute: splitHours[1],
    });
    return formatedDate;
  };

  const handleChangePreOrderItem = (menuId, value) => {
    const tempMenuItem = cloneDeep(menuItem);
    const menu = tempMenuItem.find((menu) => menu.id === menuId);

    if (menu) {
      menu.qty += value;

      // Ensure qty is within valid range
      if (menu.qty < 0) menu.qty = 0;
      if (menu.qty > menu.bookingMaxQty) {
        // Handle max quantity error
        return;
      }

      setMenuItem([...tempMenuItem]);

      // Update bookingTable.menuItems
      const updatedMenuItems = tempMenuItem.filter((item) => item.qty > 0);

      setBookingTable({
        ...bookingTable,
        menuItem: updatedMenuItems,
      });
    }
  };

  const handleNext = (tableListForMerge) => {
    setSelectedTables(tableListForMerge);
    setReview(true);
    handleCloseConfirmTable();
  };

  const getDiningOptions = () => {
    const dinningDailyQtyCheck = dinningData.filter(
      (dinning) => dinning.dailyTotalQtyleft > 0
    );
    if (filterPrivate) {
      return dinningDailyQtyCheck.filter(
        (diningOption) => !diningOption.overridePrivateRoom
      );
    }
    return dinningDailyQtyCheck;
  };

  const calculateTotalAmount = () => {
    let totalDeposit = 0;
    if (bookingTable.dinningOptions.originalPrice > 0) {
      totalDeposit +=
        bookingTable.dinningOptions.originalPrice *
        bookingTable.dinningOptions.qty;
    }

    if (bookingTable.menuItem.filter((item) => item.qty > 0).length > 0) {
      bookingTable.menuItem.forEach((item) => {
        totalDeposit += item.originalPrice * item.qty;
      });
    }

    if (bookingTable.privateRoom) {
      totalDeposit += bookingTable.privateRoom.originalPrice;
    }

    return totalDeposit.toFixed(2);
  };

  // deposit price
  const getDepositPrice = () => {
    let totalDeposit = 0;
    if (bookingTable.dinningOptions.price > 0) {
      totalDeposit +=
        bookingTable.dinningOptions.price * bookingTable.dinningOptions.qty;
    }

    if (bookingTable.menuItem.filter((item) => item.qty > 0).length > 0) {
      bookingTable.menuItem.forEach((item) => {
        totalDeposit += item.price * item.qty;
      });
    }

    return totalDeposit.toFixed(2);
  };

  return (
    <React.Fragment>
      {confirmTable && (
        <ConfirmTable
          open={confirmTable}
          handleCloseConfirmTable={handleCloseConfirmTable}
          handleNext={handleNext}
          getTableInfo={{ ...bookingTable }}
          bookingType={"NORMAL_RESERVATION"}
        />
      )}
      <Dialog open={open} onClose={handleCloseBookingTable}>
        <ValidatorForm
          onSubmit={() => getBookingTablesAction(bookingTable)}
          autoComplete="off"
          className="popup-layout"
        >
          <Box className="popup-header">
            <DialogTitle>Add New Booking</DialogTitle>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <FormControl fullWidth size="small" sx={{ width: "160px" }}>
                <InputLabel id="status">Booking</InputLabel>
                <Select
                  labelId="booking"
                  id="booking"
                  value={bookingTable.bookingType}
                  name="bookingType"
                  label="booking"
                  onChange={handleChangeBookingType}
                >
                  {reservationType.map((data, index) => (
                    <MenuItem key={index} value={data.id}>
                      {data.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack sx={{ width: "160px" }} className="date-picker">
                  <DesktopDatePicker
                    disabled
                    value={props.filterData.date}
                    inputFormat="DD-MM-YYYY"
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
              </LocalizationProvider>
            </Box>
          </Box>

          {!review ? (
            <DialogContent sx={{ width: "600px" }} className="popup-body">
              <div className="popup-input-box w-50">
                <Typography>First Name</Typography>
                <TextValidator
                  size="small"
                  fullWidth
                  margin="normal"
                  type="text"
                  name="name"
                  value={bookingTable.name}
                  placeholder="Enter First Name"
                  sx={{ marginTop: 0 }}
                  onChange={handleChangeBookingTable}
                  validators={["required"]}
                  errorMessages={["First Name is required"]}
                />
              </div>

              <div className="popup-input-box w-50">
                <Typography>Last Name</Typography>
                <TextValidator
                  size="small"
                  fullWidth
                  margin="normal"
                  type="text"
                  name="lastName"
                  value={bookingTable.lastName}
                  placeholder="Enter Last Name"
                  sx={{ marginTop: 0 }}
                  onChange={handleChangeBookingTable}
                  validators={["required"]}
                  errorMessages={["Last Name is required"]}
                />
              </div>

              <div className="popup-input-box w-50">
                <Typography>Email</Typography>
                <TextValidator
                  size="small"
                  fullWidth
                  margin="normal"
                  type="email"
                  name="email"
                  value={bookingTable.email}
                  placeholder="Enter Email"
                  sx={{ marginTop: 0 }}
                  onChange={handleChangeBookingTable}
                  validators={["isEmail"]}
                  required={true}
                  errorMessages={["Email is not valid"]}
                />
              </div>

              <div className="popup-input-boxs w-50">
                <Typography>Mobile Number</Typography>
                <PhoneInput
                  className="w-100"
                  country={"sg"}
                  inputProps={{
                    name: "phone",
                    required: true,
                  }}
                  onChange={(phone) =>
                    handleChangeBookingTable({
                      target: { name: "mobileNo", value: phone },
                    })
                  }
                />
              </div>
              <div className="popup-input-box w-50">
                <Typography>Adult</Typography>
                <TextValidator
                  size="small"
                  fullWidth
                  margin="normal"
                  type="number"
                  name="noOfAdult"
                  value={bookingTable.noOfAdult}
                  placeholder="Enter Adult"
                  sx={{ marginTop: 0 }}
                  onChange={handleChangeBookingTable}
                  validators={["required", "minNumber:1"]}
                  errorMessages={[
                    "Adult is required",
                    "Adult should be more than 1",
                  ]}
                />
              </div>
              <div className="popup-input-box w-50">
                <Typography>Child</Typography>
                <TextValidator
                  size="small"
                  fullWidth
                  margin="normal"
                  type="number"
                  name="noOfChild"
                  value={bookingTable.noOfChild}
                  placeholder="Enter Child"
                  sx={{ marginTop: 0 }}
                  onChange={handleChangeBookingTable}
                  validators={["minNumber:0"]}
                  errorMessages={["No. of Child should be valid"]}
                />
              </div>

              <div className="popup-input-box w-50">
                {timeSlots?.length > 0 && (
                  <React.Fragment>
                    <Typography>Time Slot</Typography>
                    <FormControl>
                      <Select
                        size="small"
                        id="startTime"
                        value={bookingTable.startTime}
                        name="startTime"
                        onChange={handleChangeBookingTable}
                      >
                        {timeSlots.map((data, index) => (
                          <MenuItem key={index} value={data}>
                            {data}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </React.Fragment>
                )}
              </div>

              {getDiningOptions().length > 0 && (
                <Fragment>
                  {dinningData.length > 0 && (
                    <div className="popup-input-box w-50">
                      <React.Fragment>
                        <Typography>Dining Options</Typography>
                        <FormControl>
                          <Select
                            size="small"
                            required
                            id="dinningOptions"
                            value={
                              bookingTable &&
                              bookingTable.dinningOptions &&
                              bookingTable.dinningOptions.length > 0
                                ? bookingTable.dinningOptions[0]?.id || ""
                                : ""
                            }
                            name="dinningOptions"
                            onChange={handleChangeBookingTable}
                            renderValue={(selectedId) => {
                              if (!selectedId) return "";
                              const selectedOption = dinningData.find(
                                (option) => option.id === selectedId
                              );
                              return selectedOption?.name || "";
                            }}
                          >
                            {getDiningOptions().map((option) => (
                              <MenuItem
                                key={option.id}
                                value={option.id}
                                disabled={!option.isEnable}
                              >
                                <Card
                                  className={`dining-options-card-admin ${
                                    option.id ===
                                    bookingTable.dinningOptions?.id
                                      ? "active-card"
                                      : ""
                                  }`}
                                >
                                  <div className="card-image1">
                                    <img
                                      src={`${ENVIRONMENT_VARIABLES.Base_IMAGE_URL}${option.image}`}
                                      alt={option.name}
                                    />
                                  </div>
                                  <div className="menu-item-info">
                                    <span>{option.name}</span>
                                    <Box>
                                      <span>${option.price} / pax</span>
                                    </Box>
                                  </div>
                                  {!option.isEnable && (
                                    <div className="error-message">
                                      <span>{option.message}</span>
                                    </div>
                                  )}
                                </Card>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </React.Fragment>
                    </div>
                  )}

                  {menuItem.length > 0 && (
                    <div className="popup-input-box w-50">
                      <React.Fragment>
                        <Typography>Pre-Order Menu Items</Typography>
                        <FormControl>
                          <Select
                            size="small"
                            id="menuItem"
                            multiple
                            value={bookingTable.menuItem.map((item) => item.id)}
                            name="menuItem"
                            onChange={handleChangeBookingTable}
                            renderValue={(selectedIds) =>
                              selectedIds
                                .map(
                                  (id) =>
                                    menuItem.find((m) => m.id === id)?.name
                                )
                                .join(", ") || ""
                            }
                          >
                            {menuItem.map((product) => (
                              <MenuItem
                                key={product.id}
                                value={product.id}
                                disabled={!product.isEnable}
                              >
                                <Card
                                  className={`dining-options-card-admin w-100 ${
                                    bookingTable.menuItem.some(
                                      (m) => m.id === product.id
                                    )
                                      ? "active-card"
                                      : ""
                                  }`}
                                >
                                  <div className="card-image1">
                                    <img
                                      className="card-images"
                                      src={`${ENVIRONMENT_VARIABLES.Base_IMAGE_URL}${product.image}`}
                                      alt={product.name}
                                    />
                                  </div>

                                  <div className="menu-item-info">
                                    <span
                                      className="menu-item-name"
                                      title={product.name}
                                    >
                                      {product.name}
                                    </span>

                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div className="card-price-qty">
                                        <span className="item-price pre-order-item-price">
                                          $&nbsp;{product.price}
                                        </span>

                                        <Box className="item-add">
                                          <Button
                                            sx={{
                                              border: "unset",
                                              padding: "0px",
                                              width: "24px",
                                              height: "24px",
                                              minWidth: "24px",
                                            }}
                                            variant="outlined"
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              handleChangePreOrderItem(
                                                product.id,
                                                -1
                                              );
                                            }}
                                          >
                                            <RemoveIcon />
                                          </Button>
                                          &nbsp;
                                          <span>{product.qty}</span>
                                          &nbsp;
                                          <Button
                                            sx={{
                                              border: "unset",
                                              padding: "0px",
                                              width: "24px",
                                              height: "24px",
                                              minWidth: "24px",
                                            }}
                                            variant="outlined"
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              handleChangePreOrderItem(
                                                product.id,
                                                1
                                              );
                                            }}
                                          >
                                            <AddIcon />
                                          </Button>
                                        </Box>
                                      </div>
                                      <span
                                        className="pre-order-item-detail item-detail"
                                        style={{ width: "95%" }}
                                      >
                                        {product.description}
                                      </span>
                                    </Box>
                                  </div>

                                  {!product.isEnable && (
                                    <div className="error-message">
                                      <span>{product.message}</span>
                                    </div>
                                  )}
                                </Card>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </React.Fragment>
                    </div>
                  )}

                  {getPrivateRoom().length > 0 && (
                    <div className="popup-input-box w-50">
                      <React.Fragment>
                        <Typography>Private Room</Typography>
                        <FormControl>
                          <Select
                            size="small"
                            id="privateRoom"
                            value={bookingTable.privateRoom?.id || ""}
                            name="privateRoom"
                            onChange={handleChangeBookingTable}
                            renderValue={(selected) =>
                              privateData.find((item) => item.id === selected)
                                ?.name || ""
                            }
                          >
                            {getPrivateRoom().map((room) => (
                              <MenuItem key={room.id} value={room.id}>
                                <Card
                                  className={`dining-options-card-admin ${
                                    room.id === bookingTable.privateRoom?.id
                                      ? "active-card"
                                      : ""
                                  }`}
                                >
                                  <div className="card-image1">
                                    <img
                                      src={`${ENVIRONMENT_VARIABLES.Base_IMAGE_URL}${room.image}`}
                                      alt={room.name}
                                    />
                                  </div>
                                  <div className="menu-item-info">
                                    <span>{room.name}</span>
                                    <Box>
                                      <span>${room.originalPrice}</span>
                                    </Box>
                                  </div>
                                </Card>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </React.Fragment>
                    </div>
                  )}
                </Fragment>
              )}

              <div className="popup-input-box w-100">
                <Typography>Special Instructions</Typography>
                <TextValidator
                  size="small"
                  fullWidth
                  margin="normal"
                  multiline
                  type="text"
                  name="specialRequest"
                  value={bookingTable.specialRequest}
                  placeholder="Enter Special Instructions"
                  sx={{ marginTop: 0 }}
                  onChange={handleChangeBookingTable}
                />
              </div>
            </DialogContent>
          ) : (
            // booking summary
            <DialogContent
              sx={{ width: "600px" }}
              className="popup-body booking-info"
            >
              <div className="popup-input-box w-50">
                <Typography>First Name</Typography>
                <p>{bookingTable.name}</p>
              </div>

              <div className="popup-input-box w-50">
                <Typography>Last Name</Typography>
                <p>{bookingTable.lastName}</p>
              </div>

              <div className="popup-input-box w-50">
                <Typography>Email</Typography>
                <p>{bookingTable.email}</p>
              </div>

              <div className="popup-input-boxs w-50">
                <Typography>Mobile Number</Typography>
                <p>
                  {bookingTable.mobileNo
                    ? parsePhoneNumber(`+${bookingTable.mobileNo}`)
                      ? parsePhoneNumber(
                          `+${bookingTable.mobileNo}`
                        ).formatInternational()
                      : bookingTable.mobileNo
                    : "N/A"}
                </p>
              </div>
              <div className="popup-input-box w-50">
                <Typography>Adult</Typography>
                <p>{bookingTable.noOfAdult}</p>
              </div>
              {bookingTable.noOfChild > 0 && (
                <div className="popup-input-box w-50">
                  <Typography>Child</Typography>
                  <p>{bookingTable.noOfChild}</p>
                </div>
              )}

              <div className="popup-input-box w-50">
                <Typography>Time Slot</Typography>
                <p>{bookingTable.startTime}</p>
              </div>
              <Fragment>
                {bookingTable.dinningOptions &&
                  bookingTable.dinningOptions.length > 0 && (
                    <div className="popup-input-box w-50">
                      <Typography>Dining Options</Typography>
                      {bookingTable.dinningOptions.map((option) => (
                        <li key={option.id}>{option.name}</li>
                      )) || <p>N/A</p>}
                    </div>
                  )}
                {bookingTable.menuItem && bookingTable.menuItem.length > 0 && (
                  <div className="popup-input-box w-50">
                    <Typography>Pre-Order Menu Items</Typography>
                    <ul>
                      {bookingTable.menuItem.map((item) => (
                        <li key={item.id}>
                          {item.name} (Qty: {item.qty})
                        </li>
                      )) || <p>N/A</p>}
                    </ul>
                  </div>
                )}
                {bookingTable.privateRoom && (
                  <div className="popup-input-box w-50">
                    <Typography>Private Room</Typography>
                    <p>{bookingTable.privateRoom.name || "N/A"}</p>
                  </div>
                )}
              </Fragment>
              {/* new design end */}
              {bookingTable.specialRequest && (
                <div className="popup-input-box w-100">
                  <Typography>Special Instructions</Typography>
                  <p>{bookingTable.specialRequest}</p>
                </div>
              )}

              {/* Total price */}
              <div className="popup-input-box w-50">
                <Typography>Total Price</Typography>
                <p>
                  ${calculateTotalAmount() > 0 ? calculateTotalAmount() : "N/A"}
                </p>
              </div>

              {/* Deposit Amount */}
              <div className="popup-input-box w-50">
                <Typography>Deposit Amount</Typography>
                <p>${getDepositPrice() > 0 ? getDepositPrice() : "N/A"}</p>
              </div>
            </DialogContent>
          )}
          <DialogActions className="primary-btn popup-btn">
            <Button variant="outlined" onClick={handleCloseBookingTable}>
              <CloseOutlinedIcon /> Close
            </Button>
            {!review ? (
              <Button
                disabled={isEmpty(bookingTable.startTime)}
                type="submit"
                variant="contained"
              >
                NEXT
              </Button>
            ) : (
              <Button onClick={confirmTables} variant="contained">
                EMAIL
              </Button>
            )}
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    </React.Fragment>
  );
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(NormalBooking);
